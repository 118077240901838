// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'Development',

  apiUrl : 'https://paradiseeasyinapi.mnc-control.com/',


  firebaseConfig : {
    apiKey: "AIzaSyDaRt_ZbNcmRm9MGuSdwBSJ0KzSm4fpB0E",
    authDomain: "paradise-2c860.firebaseapp.com",
    databaseURL: "https://paradise-2c860.firebaseio.com",
    projectId: "paradise-2c860",
    storageBucket: "paradise-2c860.appspot.com",
    messagingSenderId: "436557900271",
    appId: "1:436557900271:web:57c8819bcbed468f5aee1a",
    measurementId: "G-BRX2KRSQJQ"
  },
  whitelistedDomains: ['paradiseeasyinapi.mnc-control.com'],
  blacklistedRoutes: ['paradiseeasyinapi.mnc-control.com/ClientAuthentication']

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in
 * mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
