import { GeneralFunctions } from 'src/app/common/general-functions';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})

export class NotFoundComponent {
  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router,
    public general: GeneralFunctions) {
    this.settings = this.appSettings.settings;
  }

  searchResult(): void {
    this.router.navigate(['/search']);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

}
