import { ResultModel } from 'src/app/models/result-model';
import { CustomPagingService } from 'src/app/services/custom-paging.service';
import { FiltrationModel } from 'src/app/models/filtration-model';
import { LocalPagerService } from 'src/app/services/local-pager.service';
import { OnInit, AfterViewInit, Input, Output, EventEmitter, Component } from '@angular/core';
import { PagerModel } from 'src/app/models/pager-model';
import { Action } from 'src/app/models/action';
import * as _ from 'underscore';
import { AppError } from 'src/app/common/app-error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-paging',
  templateUrl: './custom-paging.component.html',
  styleUrls: ['./custom-paging.component.css']
})
export class CustomPagingComponent implements OnInit , AfterViewInit {

  constructor(private pagerService: LocalPagerService, private service: CustomPagingService,public router: Router) { }
  public page = 1;
  disableEdit = true;
  disableDelete = true;
  disableAccount = true;
  disableNodes = true;
  filtration: FiltrationModel;
  pageSize = 10;
  resultData: any[];
  pager: PagerModel;
  pagedItems: any[];
  // tslint:disable-next-line:no-input-rename
  @Input('action') action: Action;

  @Output() pagingEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() accountEvent = new EventEmitter();
  @Output() nodesEvent = new EventEmitter();
  ngOnInit() {
  }
  ngAfterViewInit() {
    debugger;
    if (this.action.pagingType === 'localPaging') {
      this.getPageData();
      } else {
        this.filtration = {pageSize:  this.pageSize, pageNumber: 1 , filterObject: this.action.filter};
        this.getData_ServerPaging();
      }
  }
  getPageData() {
    debugger;
    this.service.getResultbyActionName(this.action.ActionName, this.action.filter).subscribe(result => {
      this.resultData = result;
      console.log("test");
      console.log( this.resultData);
       this.setPage(1);
    }
    // ,
    // (error: AppError) => {
    //   this.router.navigate(['error']);
    // }
    );
  }
  getData_ServerPaging() {
    this.service.getResultbyActionName(this.action.ActionName, this.action.filter).subscribe((result: ResultModel) => {
      this.pagingEvent.emit(result.result);
      this.pager = {currentPage: this.filtration.pageNumber ,
                   pageSize: this.filtration.pageSize ,
                   totalPages: result.pagesCount,
                   pages: [],
                   endIndex: 0,
                   endPage: 0,
                   startIndex: 0,
                   startPage:  0,
                   totalItems: 0
                  };
    });
  }
  pageSizeChange(pageSize) {
    this.pageSize = pageSize;
    this.setPage(1);
  }
  setPage(page: number) {
    if (this.action.pagingType === 'localPaging') {
      if (this.resultData && this.resultData.length > 0) {
        this.pager = this.pagerService.getPager(this.resultData.length, page, this.pageSize);
        // console.log(this.pager);
        // get current page of items
        this.pagedItems = this.resultData.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.pagingEvent.emit(this.pagedItems);
      } else {
        this.pagingEvent.emit([]);
      }
    } else {
      this.filtration = {pageSize:  this.pageSize, pageNumber: page , filterObject: this.action.filter};
      this.action.filter.pageSize=this.pageSize;
      this.action.filter.pageNumber=page;
      this.getData_ServerPaging();
    }
  }

  setActionControls(isEnabled) {
  this.disableDelete = this.disableEdit = this.disableAccount = this.disableNodes = !isEnabled;
  }

  sort($event) {
    if (this.resultData) {
      if ($event.sorts[0].dir === 'desc') {
        this.resultData = _.sortBy(this.resultData, $event.sorts[0].prop);
        this.resultData.reverse();

      } else {
        this.resultData = _.sortBy(this.resultData, $event.sorts[0].prop);
      }
      this.setPage(1);
    } else {

    }
  }

  deleteRow (selected) {
    const index: number = this.resultData.indexOf(selected);
    if (index !== -1) {
        this.resultData.splice(index, 1);
        this.setPage(1);
    }
  }

  addClick() {
    this.addEvent.emit();
  }
  editClick() {
    this.editEvent.emit();
  }
  deleteClick() {
    this.deleteEvent.emit();
  }
  accountClick() {
    this.accountEvent.emit();
  }
  nodesClick() {
    this.nodesEvent.emit();
  }
}
