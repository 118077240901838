import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {


  constructor(private router: Router, private authService: AuthService) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = localStorage.getItem('Mytoken');
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      let roles = _route.data['permittedRoles'] as Array<string>;
      if (roles) {
        if (this.authService.roleMatch(roles)) return true;
        else {
          this.router.navigate(['/ForbiddenPage'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }
      return true;
    }
  }
}
