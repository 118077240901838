import { GeneralFunctions } from 'src/app/common/general-functions';
import { ErrorService } from './../../../services/error.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { Request } from 'src/app/models/request-message';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})

export class ErrorComponent {
  error: Request;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router, public general: GeneralFunctions,
     private errorService: ErrorService, private teanslate: TranslateService) {
    this.settings = this.appSettings.settings;
    this.errorService.setError.subscribe(m => this.error = m);
    if (this.error.code === 401) {
      localStorage.removeItem('Mytoken');
    }
    if (this.error.code === 0) {
      this.router.navigate(['/**']);
    }
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

}
