import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Request } from '../models/request-message';

@Injectable({
    providedIn: 'root'
  })
  export class ErrorService {
  public setError = new BehaviorSubject<Request>({code: 0, message: ''});
  }
