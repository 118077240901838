import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })

export class GeneralFunctions {

    constructor(private snackBar: MatSnackBar,
        private translate: TranslateService) {}

    translateKey(key: string ) {
        let value = '';
        value = this.translate.instant(key);
        return value;
    }
    showMessageINUoload(message: string, action: string) {
        //s  message = this.translateKey(message);
        //  action = this.translateKey(action);
          this.snackBar.open(message, action, {
            duration: 15000,
          });
      } 


    showMessage(message: string, action: string) {
        message = this.translateKey(message);
        action = this.translateKey(action);
        this.snackBar.open(message, action, {
          duration: 2000,
        });
    }
}
