import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { MessagesService } from './messages.service';
import { HandleNotificationService } from 'src/app/services/handle-notification.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { OnPageVisibilityChange, AngularPageVisibilityStateEnum } from 'angular-page-visibility';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MessagesService ]
})
export class MessagesComponent implements OnInit {  
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab:number=1;
  public messages:Array<Object>;
  public files:Array<Object>;
  public meetings:Array<Object>;  
  notificationList;
  constructor(private messagesService:MessagesService,private NotificationService:HandleNotificationService,private messagingService: MessagingService) { 
    this.messages = messagesService.getMessages();
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();    
  }

  ngOnInit() {
    debugger;
    var accountId=localStorage.getItem('accountId')
    var email=localStorage.getItem('email').toLowerCase();
    email=email.substring(0, email.lastIndexOf("@"));
    var topic=accountId+email;
    this.messagingService.requestPermission(topic)
    this.messagingService.receiveMessage()
    localStorage.setItem('NotificationCount','0');
    this.NotificationService.getNotificationsCount(accountId).subscribe(response => {
      if (response) {
        localStorage.setItem('NotificationCount',""+response+"")
      }}
      );
  }

  openMessagesMenu() {
    var accountId=localStorage.getItem('accountId')
    this.NotificationService.getNotifications(accountId).subscribe(response => {
      if (response) {
       this.notificationList=response;
       console.log(this.notificationList);
      }}
      );
    this.trigger.openMenu();
    this.selectedTab = 0;
  }
  onNotificationClick(notificationId,notificationType,objectId,isSeen){
    debugger;
    console.log("notificationId : "+notificationId+" // notificationType : "+notificationType+" // objectId : "+objectId);
    this.NotificationService.handleNotification(notificationId,notificationType,objectId);
    if(isSeen==false){
      var NotificationCount=+localStorage.getItem('NotificationCount');
      NotificationCount--;
      localStorage.setItem('NotificationCount',""+NotificationCount+"")
    }
    
  }
  stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }
  public getNotificationCount(){
    var count=+localStorage.getItem('NotificationCount');
    if(count){
      return count;
    }
    return null;
  }
  resetNotificationCount(){
    var accountId=localStorage.getItem('accountId')
      this.NotificationService.getNotificationsCount(accountId).subscribe(response => {
        if (response) {
          localStorage.setItem('NotificationCount',""+response+"")
        }}
        );
  }
  @OnPageVisibilityChange()
  logWhenPageVisibilityChange ( visibilityState: AngularPageVisibilityStateEnum ): void {
    if ( AngularPageVisibilityStateEnum[visibilityState]=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]) {
      console.log( 'OnPageVisibilityChange => visible' );
      this.resetNotificationCount();
    } 
    else if (AngularPageVisibilityStateEnum[visibilityState]=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.HIDDEN]) {
      console.log( 'OnPageVisibilityChange => hidden' );
    }
  }
 

}
