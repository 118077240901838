import { Component, OnInit, Input, ComponentFactoryResolver, Type, Injector } from '@angular/core';
import { TabLink } from 'src/app/models/tab-link';


@Component({
  selector: 'app-tablink',
  templateUrl: './tabLink.component.html',
  styleUrls: ['./tabLink.component.scss']
})

export class TabLinkComponent implements OnInit {
  @Input() tabs: TabLink[];
  myInjector: Injector;
  constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {

   }
  ngOnInit() {
    // this.myInjector =
    // Injector.create({providers: [{provide: Greeter, useValue: 'this.tabs[index].componentId', deps: []}], parent: this.injector});
    this.initiFirstTabs();
  }
  initiFirstTabs() {
    this.loadTab(0, this.tabs[0].componentId);
  }
  loadTabClick($event) {
    this.loadTab($event.index, this.tabs[$event.index].componentId);
  }
  loadTab(index: number, componentName: string) {
    if (!this.tabs[index].loaded) {
      const factories = Array.from(this.resolver['_factories'].keys());
      const factoryClass = <Type<any>>factories.find((x: any) => x.name === componentName);
      const factory = this.resolver.resolveComponentFactory(factoryClass);

      console.log(factory);

      // this.myInjector =
      // Injector.create({providers: [{provide: Greeter, useValue: 'this.tabs[index].componentId', deps: []}], parent: this.injector});

      this.tabs[index].loaded = true;
      this.tabs[index].component = factory.componentType;
    }
  }
}
