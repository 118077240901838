import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    fcm_server_key = "AAAAd295f-M:APA91bEVu7Mqs1k5llA8VkBq8Yde5h4uDVydq4nyob3enY1NVNYnMuiNo7h6E1OpdO7ZONXNqHxKRGFGoUT9xXvxVAD8yUoHTMMXzBIamWpZYKJ44xSYinZVl8S2TpCI4Njt9OAifIM7";
    currentToken;
    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission(topic) {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                console.log(token);
                this.currentToken = token;
                this.subscribeTokenToTopic(topic);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
                var NotificationCount=+localStorage.getItem('NotificationCount');
                NotificationCount++;
                localStorage.setItem('NotificationCount',""+NotificationCount+"")
            })
    }
    subscribeTokenToTopic(topic) {
        fetch('https://iid.googleapis.com/iid/v1/' + this.currentToken + '/rel/topics/' + topic, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'key=' + this.fcm_server_key
            })
        }).then(response => {
            if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
            }
            console.log('Subscribed to "' + topic + '"');
        }).catch(error => {
            console.error(error);
        })
    }
}