import { Request } from './../models/request-message';
import { User } from 'src/app/models/user-model';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { PagingService } from './paging.service';
import { Paging } from '../models/paging-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements PagingService {
  private paging: Paging = {pageNumber: 1, pagesCount: 0, dataSource: [],
     length: 5, totalItemsCount: 0, filter: {userName: ''}, actionName: ''};
  glopalPaging = new BehaviorSubject<Paging>(this.paging);
  private jwtHelper = new JwtHelperService();
  private API_URL  =  environment.apiUrl+'ClientAuthentication/';
  
  baseURL = environment.apiUrl;
  constructor(
    private  httpClient:  HttpClient
    ) {
      this.glopalPaging.subscribe(m => {
        this.paging = m;
        console.log(m);
      });
    }

    login(model: User) {
      return this.httpClient.post(this.API_URL + 'Login', model).pipe(map((respons: any) => {
        if (respons) {
          const _respons = respons;
          try {
            localStorage.setItem('Mytoken', _respons.token);
            if (!this.jwtHelper.isTokenExpired(_respons.token)) {
            localStorage.setItem('email', model.userName);
            localStorage.setItem('accountId', _respons.accountId);
            return true;
            } else {
              return false;
              }
          } catch {
                return false;
            }
        } else {
            return false;
          }
      })
    );
  }
    loggedIn() {
      const token = localStorage.getItem('Mytoken');
      return !this.jwtHelper.isTokenExpired(token);
    }

    getByPaging(pagingObj) {
      return this.httpClient.
      post<Paging>(this.API_URL + this.paging.actionName, pagingObj);
    }

    sendPasswordChangeUrl(userName, clientEntityId) {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.get<boolean>(this.baseURL + 'ClientAuthentication/sendPasswordChangeUrl?email=' + userName + '&&clientEntityId=' + clientEntityId + '' );
    }

    canChangePassword(token) {
     return this.httpClient.get<Request>(this.baseURL + 'ClientAuthentication/CanChangePassword?token=' + token + '' ).pipe(
      map( m => {
        return m;
      }));
    }

    resetPassword(user) {
     return this.httpClient.post<Request>(this.baseURL + 'ClientAuthentication/ResetPassword', user ).pipe(
      map( response => {
        return response;
      }));
    }

    changePassword(user) {
      return this.httpClient.post<Request>(this.baseURL + 'ClientAuthentication/ChangePassword', user ).pipe(
       map( response => {
         return response;
       }));
     }

     roleMatch(allowedRoles): boolean {
       debugger;
      var isMatch = false;
      var payLoad = JSON.parse(window.atob(localStorage.getItem('Mytoken').split('.')[1]));
      var userRole = payLoad.role;
      allowedRoles.forEach(element => {
        if (userRole.indexOf(element) > -1) {
          isMatch = true;
          return false;
        }
      });
      return isMatch;
    }

}
