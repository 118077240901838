import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appDecimaNumber]'
})
export class NumericDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('decimal') decimal: Number; // number | decimal
  // tslint:disable-next-line:no-input-rename
  @Input('nLength') nLength: Number;
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,5}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

  ngOnInit(): void {
  }
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    const decimalVal = next.split('.')[1];
    const numberVal = next.split('.')[0];
    if ((next && !String(next).match(this.regex)) || (decimalVal && decimalVal.length > this.decimal) || (numberVal && numberVal.length > this.nLength)) {
      event.preventDefault();
    }
  }
}
