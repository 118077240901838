
import { Component, OnInit, Input, Inject } from '@angular/core';
import { PagingService, service } from 'src/app/services/paging.service';
import { Paging } from 'src/app/models/paging-model';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css']
})

export class PagingComponent implements OnInit {
  public page = 1;
  public count = 5;
  public pagingModel: Paging = {pageNumber: 1, pagesCount: 0, dataSource: [],
     length: 5, totalItemsCount: 0, filter: {}, actionName: ''};
  pagingService;
  // tslint:disable-next-line:no-input-rename
  @Input('service') service;
  constructor(@Inject(service) private serviceInjected: any) {
  }

  ngOnInit() {
    this.pagingService = this.serviceInjected;
    this.pagingService.obj.subscribe(m => this.pagingModel = m);
  }

  getByPaging() {
    this.pagingService.getByPaging(this.pagingModel).subscribe(m => {
      this.pagingService.obj.next(m);

    });
  }
  paging(num: number) {
    this.pagingModel.pageNumber = this.pagingModel.pageNumber + num;
    this.getByPaging();
  }

  getByPage(num: number) {
    if (num > this.pagingModel.pagesCount) {
      this.pagingModel.pageNumber = this.pagingModel.pagesCount;
      this.page = this.pagingModel.pagesCount;
    } else if (num < 1) {
      this.pagingModel.pageNumber = 1;
      this.page = 1;
    } else {
      this.pagingModel.pageNumber = num;
    }
    this.getByPaging();
  }

  changeTableItemsCount() {
    this.pagingModel.length = this.count;
    this.pagingModel.pageNumber = 1;
    this.getByPaging();
  }

}
