import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';
import { Router } from '@angular/router';
import { GeneralFunctions } from '../common/general-functions';
import { AppError } from '../common/app-error';

@Injectable({
  providedIn: 'root'
})
export class HandleNotificationService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService, private router: Router, public general: GeneralFunctions) { }

  handleNotification(notificationId, notificationType, objectId) {
    debugger;
    var AdminEmail = localStorage.getItem('email');
    this.handleNotificationAPI(notificationId, AdminEmail).subscribe(Response => {
      if (Response) {
        var result: any;
        result = Response;
        console.log(result);
        if (result.requestStatus == 1) {
          switch (notificationType) {
            case ("ServiceRequestReply"):
            case ("ServiceRequestCreated"):
              this.general.showMessage(result.message, 'handleNotification');
              this.router.navigate(['EditTicket'], { queryParams: { ticketId: objectId } });
              break;
            case ("RegistrationRequestCreated"):
              this.router.navigate(['AddOwner'], { queryParams: { ownerId: objectId } });
              break;
            default:
              this.general.showMessage("some thing wrong in this notification Type", 'handleNotification');
              break;
          }

        }
        else if (result.requestStatus == 0) {
          this.general.showMessage(result.message, 'handleNotification');
        }
      } else {
        this.router.navigate(['error']);
      }
    },
      (error: AppError) => {
        this.router.navigate(['error']);
      });
  }

  handleNotificationAPI(notificationId, loggedUserEmail) {
    return this.http.get<Request>(this.baseURL + 'HandleNotification/HandleNotification?notificationId=' + notificationId + '&loggedUserEmail=' + loggedUserEmail);
  }
  getNotificationsCount(accountId) {
    return this.http.get<Request>(this.baseURL + 'HandleNotification/GetNotificationsCount?accountId=' + accountId);
  }
  getNotifications(accountId) {
    return this.http.get<Request>(this.baseURL + 'HandleNotification/GetNotifications?accountId=' + accountId);
  }


}
