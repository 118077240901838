import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  public getlocal(){return localStorage.getItem('NotificationCount');}
  constructor(public appSettings: AppSettings, private translate: TranslateService) {
      this.settings = this.appSettings.settings;
      translate.setDefaultLang('en');
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
   console.log('You are in '+environment.mode+ ' environment');
   }
}
