import { ErrorService } from './error.service';
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate {
  token: string;
  canRoute: boolean;
  constructor(private router: Router, private authService: AuthService, private errorService: ErrorService) { }

  canActivate(route: ActivatedRouteSnapshot , state: RouterStateSnapshot) {
    this.token = route.queryParamMap.get('token');
      return this.authService.canChangePassword(this.token).pipe(
      map(m => {
        if (m.code !== 200) {
          this.errorService.setError.next(m);
          this.router.navigate(['/error']);
          return false;
         } else {return true; }
      }));
   /*  setTimeout(() => {
  }, 1000); */
  }
}

